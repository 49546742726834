import React,{useState,useEffect} from 'react';
import Nav from '../layout/Topnav';
import Footer from '../layout/Footer';
import Header from '../layout/Pageheader';
import Customer from './Customer';
import Client from './Client';
import AboutUs from './AboutUs';
import Vision from './Vision';
import Mission from './Mission';
import DocumentMeta from 'react-document-meta';
import axios from "axios";
import {URL} from '../Request';
import Partner from "./Partner";


const About = () => {

  const [Seo, setState] = useState([])
  const pagename = "about";


  useEffect(() => {
     var config = {
         headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
         },
         withCredentials: false
       }
     axios(URL+'api/mk/get_page/'+pagename,config)
         .then(function (response) {
             setState(response.data);
         });
   }, [setState]); 

  const meta = {
      title: Seo.meta_title,
      description: Seo.meta_description,
      meta: {
        name: {
          keywords: Seo.key_words,
        }
      }
    };


    return(
        <>
          <DocumentMeta {...meta}>
          <Nav/>
          <Header title="About"/>
          <AboutUs/>
          <Vision/>
          <Mission/>
          {/*<Partner/>*/}
          <Customer/>
          <Client/>
          <Footer/>
          </DocumentMeta>
        </>
    )
}

export default About;