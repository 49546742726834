import React,{useState} from 'react';
import Image from '../images/section/slide8.png';
import emailjs from 'emailjs-com';


const Email =()=>{
    const [state,setState] = useState({
        status: "Get Started"
      })

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_ft3kucf', 'newsletters_5s05mqw', e.target, 'user_9uU2EIZEc6USjV5WOF0GP')
          .then((result) => {
              setState({
              status: "Subscribed"
            })
          }, (error) => {
            setState({
            status: "Error"
          })
          });
      }

    return(
        <>
            <section className="get-started text-center email-section">
                <div className="container mt-5">
                    <div className="row pt-5">
                        <div className="col-lg-6  col-md-6  z-index-2 pt-5 mt-5">
                            <h3 className="mb-10px text-left text-dark">Get Actionable  Tips Via Your E-mail</h3>
                            <p className="mb-30px text-left text-dark">Get in touch and let's make something awesome together. Let's turn your idea into a greater product.</p>
                            <form className="p-relative" onSubmit={sendEmail} method="POST" >
                                <input type="email" required="" name="user_email" className="radius-50px mb-10px pl-15px pt-7px pb-7px no-border w-100" placeholder="Enter your email" style={{borderRadius : '7px'}}/>
                                <button type="submit"  className="bg-orange color-fff radius-50px pr-15px pl-15px pt-7px pb-7px no-border p-absolute" style={{ backgroundColor: '#ce0c0c',borderRadius: '7px'}}>{ state.status }</button>
                            </form>
                        </div>

                        <div className="col-lg-6  col-md-6  z-index-2">
                            <div>
                                <img src={ Image } style={{ height: '500px'}} alt="mksoltech" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Email;