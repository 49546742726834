import React from 'react';
import './About.css';
import about_3 from '../images/section/about_3.png';
import Fade from 'react-reveal/Fade';

const Mission = () => {

        return(
            <>
            <section className="about-area sec-padding about_section pt-5 pb-5" >
                <div className="container mt-3 mb-3">
                    <div className="row mb-25px">
                        <div className="col-md-6">
                            <Fade bottom>
                                <div className="mt-25px mb-25px ">
                                    <span className="fs-20 fw-400 pt-10px pb-10px pr-25px pl-25px radius-50px bg-gray color-blue mktag">MKSOL TECH</span>
                                    <h3 className="mb-15px mt-20px h-heading">Our Mission </h3>
                                    <p className="p-texting">
                                    We offer custom software development and consulting services to help you pull off your next project with less stress and less cost. Our team has the technology prowess to power you at every stage of your product development lifecycle, from discovery to ongoing post-launch enhancement. UX and UI Design.</p>

                                    <p className="p-texting">
                                    • Clinet Satisfaction is our first preority.<br></br>
                                    • Project delivery time & QA control.<br></br>
                                    • We build technology to simple & smart for everyone.<br></br>
                                    • Your Technology Solutions Partner for the Digital.<br></br>
                                    </p>
                                </div>
                            </Fade>
                        </div>
                        <div className="col-md-6 text-center">
                            <div className="mt-25px mb-25px ">
                                <Fade bottom>
                                    <img src={ about_3 } alt="img" className="about_img" />
                                </Fade>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
            </>
        )
}

export default Mission;