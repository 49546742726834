import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import $ from 'jquery';
// import Popper from 'popper.js';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import HttpsRedirect from 'react-https-redirect';


import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <HttpsRedirect>
      <App />
    </HttpsRedirect>
  </React.StrictMode>,
  document.getElementById('main')
);

reportWebVitals();
