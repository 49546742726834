import React from 'react';
import './About.css';
import about_2 from '../images/section/about_2.png';
import Fade from 'react-reveal/Fade';

const Vision = () => {

        return(
            <>
            <section className="about-area sec-padding setp-section pt-5 pb-5" >
                <div className="container mt-3 mb-3">
                    <div className="row mb-25px">
                        <div className="col-md-6 text-center">
                            <div className="mt-25px mb-25px ">
                                <Fade bottom>
                                    <img src={ about_2 } alt="mksoltech about us" className="about_img" />
                                </Fade>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <Fade bottom>
                                <div className="mt-25px mb-25px ">
                                    <span className="fs-20 fw-400 pt-10px pb-10px pr-25px pl-25px radius-50px bg-gray color-blue mktag">MKSOL TECH</span>
                                    <h3 className="mb-15px mt-20px h-heading">Our Vision </h3>
                                    <p className="p-texting">
                                    MKSOL TECH (Pvt). Ltd is a I.T Software Development, Solutions and Services Company established in the year 2019.founded by Baber Cheema A Eastern visionary in ISLAMABAD and Kahuta has a unique focus to provide comprehensive and integrated software business solutions to facilitate businesses. 
                                    </p>
                                    <p className="p-texting">
                                    Our team aims to drive user adoption and satisfaction for your product with an eye for future growth. We perform user-centric analysis to align the overall experience with your business goals and user needs, and let you make well-informed product decisions.Development and Testing. We marry engineering and QA so you can reduce costs and time-to-market while building a product that is functional, scalable and secure. Then, we will stay on board to help you roll out cool new features and integrations, and increase your user base.
                                    </p>
                                </div>
                            </Fade>
                        </div>
                        
                    </div>
                </div>
            </section>
            </>
        )
}

export default Vision;