import React from 'react';
import Fade from 'react-reveal/Fade';

const Package = (props) =>{

    return (
        <>
            <div className="col-md-4 mb-4">
                <Fade bottom>
                    <div className="card card-m">
                        <div className="services-text pl-30px pr-30px mt-25px mb-25px text-center" >
                            <div className="icon_box">
                                                <i className={"fa "+props.icon+ " color-blue"} style={{fontSize: '40px',marginTop: '10px'}}></i>
                            </div>
                        
                            <div className="pl- new-d">
                                <h4 className="mb-5px main-servic">{ props.title }</h4>
                                <span className="color-blue bg-gray pl-15px pr-15px pt-5px pb-5px radius-50px fw-400 fs-14 main-subtitle">{props.subtitle}</span>
                                <p className="mt-15px main-description">

                                </p>
                                <p>
                                <span style={{ color: '#656565', fontFamily: 'Lato, sans-serif', fontSize: '14px', textAlign: 'center', backgroundColor: '#ffffff'}}>
                                {props.description}
                                </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
        </>
    )

}

export default Package;