import React,{useState,useEffect} from 'react';
import  './Contact.css';
import axios from "axios";
import {URL} from '../Request';
import Fade from 'react-reveal/Fade';
import emailjs from 'emailjs-com';

const Form = () =>{
      const [state,setState] = useState({
        name: '',
        email: '',
        subject:'',
        message: '',
        status: "Send"
      })

      const handleSubmit = (event) => {
        event.preventDefault();  
        setState({ status: "Sending" }); 
        
        emailjs.sendForm('3a0443082ac4e72d5631580d8faf9d6d', 'template_p7oc0us', state, 'user_9uU2EIZEc6USjV5WOF0GP')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
      }

      const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_ft3kucf', 'template_p7oc0us', e.target, 'user_9uU2EIZEc6USjV5WOF0GP')
          .then((result) => {
              setState({name: '',
              email: '',
              subject:'',
              message: '',
              status: "Send Message"
            })
          }, (error) => {
            setState({name: '',
            email: '',
            subject:'',
            message: '',
            status: "Error"
          })
          });
      }

      let buttonText = state.status;
      const [Page, setState_1] = useState([])

    useEffect(() => {
       var config = {
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
           },
           withCredentials: false
         }
       axios(URL+'api/mk/contact_get',config)
           .then(function (response) {
            setState_1(response.data);
           
           });
     }, [setState_1]);
    return(
        <>
            <section className="contact-area sec-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <Fade bottom>
                                <h2 className="title-h text-left" style={{ fontSize: '24px',lineHeight: '36px',color: 'black',marginBottom: '30px'}}>Get in Touch</h2>
                                <p className="title-p" style={{ margin: '0px', textAlign: 'left',fontSize: '14px',color: 'black',lineHeight: '16px',marginBottom: '28px',marginTop: '-20px'}}>Drop us a line or give us a ring. We love to hear from you and are happy to answer any questions.</p>
                                <div className="address">
                                    <p className="mb-30px"><i className="color-blue bg-gray radius-50 fs-35 mr-10px text-center fa fa-map"></i>{ Page.address }</p>
                                </div>
                                <div className="address">
                                    <p className="mb-30px"><i className="color-blue bg-gray radius-50 fs-35 mr-10px text-center fa fa-phone"></i>{ Page.phone }</p>
                                </div>
                                <div className="address">
                                    <p className="mb-30px"><i className="color-blue bg-gray radius-50 fs-35 mr-10px text-center fa fa-envelope"></i>{ Page.email }</p>
                                </div>
                            </Fade>
                        </div>
                        <div className="col-md-6">
                            <Fade bottom>
                                <div className="card card-m">
                                <h2 className="title-h text-left" style={{fontSize: '24px',lineHeight: '36px',color: 'black',marginBottom: '30px'}}>Contact Us</h2>
                                <div className="contact-form">
                                <form className="form" id="contact-form" onSubmit={sendEmail} method="POST">
                                    <div className="messages"></div>
                                    <div className="controls">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                <input type="hidden" name="contact_number" />
                                                    <input className="form-control" type="text" name="user_name" id="name" placeholder="Name" required />
                                                    <div className="help-block with-errors color-orange" id="name_error">
                                                        <ul className="list-unstyled">
                                                            <li style={{fontSize: '12px'}}>Name is required.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <input id="email" className="form-control" type="email" name="user_email"   placeholder="Email" required />
                                                    <div className="help-block with-errors color-orange" id="email_error">
                                                        <ul className="list-unstyled">
                                                            <li style={{fontSize: '12px'}}>Email is required.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="help-block with-errors color-orange" id="invalidemail">
                                                        <ul className="list-unstyled">
                                                            <li style={{fontSize: '12px'}}>Email is invalid.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <input id="subject" className="form-control" type="text" name="subject"    placeholder="Subject" required />
                                                    <div className="help-block with-errors color-orange" id="subject_error">
                                                        <ul className="list-unstyled">
                                                            <li style={{fontSize: '12px'}}>Subject is required.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <textarea id="message" className="form-control" name="message"  placeholder="Message"   rows="6" required></textarea>
                                                    <div className="help-block with-errors color-orange" id="messages_error">
                                                        <ul className="list-unstyled">
                                                            <li style={{fontSize: '12px'}}>Messages is required.</li>
                                                        </ul>
                                                    </div>
                                                    <div className="help-block with-errors color-orange" id="messages_sent">
                                                        <ul className="list-unstyled">
                                                            <li style={{fontSize: '12px',color: 'green'}}>Your Messages is Send.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <button type="submit" className="main-btn btn-3 color-fff radius-50px btn-primary color-orange-hvr" >{state.status}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Form;
