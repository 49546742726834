import React,{useState,useEffect} from 'react';
import './About.css';
import Slider from "react-slick";
import './css/slick.min.css';
import axios from "axios";
import {URL} from '../Request';
import feedback_image from '../images/section/feedback.svg';
import Fade from 'react-reveal/Fade';

const Customer = () => {

    const [Page, setState] = useState([])

    useEffect(() => {
       var config = {
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
           },
           withCredentials: false
         }
     
       axios(URL+'api/mk/feedback',config)
           .then(function (response) {
               setState(response.data);
           
           });
     }, [setState]); 

    const settings = {
        dots: true,
        infinite: true,
        // speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 2000,
      };

        return(
            <>
            <section className="about-area sec-padding setp-section pt-5 pb-5" >
                <div className="container mt-3 mb-3">
                    <div className="row mb-25px">
                    <div className="col-md-6 text-center">
                            <div className="mt-25px mb-25px ">
                                <Fade bottom>
                                    <img src={ feedback_image } alt="img" className="about_img" />
                                </Fade>
                            </div>
                        </div>
                        <div className="col-md-6">
                                <Fade bottom>
                                    <div className="mt-25px mb-25px ">
                                        <span className="fs-20 fw-400 pt-10px pb-10px pr-25px pl-25px radius-50px bg-gray color-blue mktag">MKSOL TECH</span>
                                        <h3 className="mb-15px mt-20px h-heading">What Our Customers Say About MKSOL TECH </h3>
                                        <p className="p-texting">MKSOL TECH is an IT company, providing Services in Web Designing, Web Development, Online Applications for Business Process Automation and trainings.</p>
                                        <Slider {...settings}>
                                        {Page.map((feedback) => (
                                                <div className="p-3" key={feedback.id}>
                                                    <p className="client_feedback_p">
                                                    { feedback.message}
                                                    </p>
                                                    <div className="media">
                                                        <div className="media-left">
                                                            <img src={URL+'public/upload/feedback/'+feedback.avatar} className="media-object client_feedback_image" alt={feedback.name} ></img>
                                                        </div>
                                                        <div className="media-body">
                                                            <h4 className="media-heading client_feedback_name">{ feedback.name }</h4>
                                                            <p className="client_feedback_des">{ feedback.company }</p>
                                                        </div>
                                                    </div>
                                                </div>
                                        ))} 
                                        </Slider>     
                                    </div>
                                </Fade>    
                        </div>  
                    </div>
                </div>
            </section>
            </>
        )
}

export default Customer;