import './App.css';
import Home from './Home/Home';
import About from './About/About';
import Contact from './Contact/Contact';
import Service from './Service/Service';
import Privacy from './Pages/Privacy';
import FAQ from './Pages/Faq';
import Terms from './Pages/Terms';
import Portfolio from './Portfolio/Portfolio';
import ScrollToTop from "./layout/ScrollToTop";
import E404 from './Pages/Erorr_404'; 
import ReactGA from 'react-ga';


import {BrowserRouter,Redirect,Route,Switch} from 'react-router-dom';
const App=()=> {
  ReactGA.initialize('UA-153487067-2');
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <>
      <BrowserRouter>
      <ScrollToTop/>
        <Switch>
          <Route exact path='/' component={Home}></Route>
          <Route exact path='/about' component={About}></Route>
          <Route exact path='/contact' component={Contact}></Route>
          <Route exact path='/service' component={Service}></Route>
          <Route exact path='/portfolio' component={Portfolio}></Route>
          <Route exact path='/Privacy' component={Privacy}></Route>
          <Route exact path='/faq' component={FAQ}></Route>
          <Route exact path='/terms' component={Terms}></Route>
          <Route path='*' component={E404}></Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
