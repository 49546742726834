import React from 'react';
import Nav from '../layout/Topnav';
import Footer from '../layout/Footer';
import Header from '../layout/Pageheader';
import From from './Form'

const Contact = () => {

        return(
            <>
                <Nav></Nav>

                <Header title="Contact Us"/>
                <From/>

            <Footer/>
            </>
        )
}

export default Contact;
