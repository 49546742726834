import React,{useEffect,useState} from 'react';
import { NavLink } from 'react-router-dom';
import './Laystyle.css';
import backGround_Image from '../images/back_ground/footer.jpg';
import axios from "axios";
import {URL} from '../Request';
import mainimage from '../images/banner-v2-img.jpg';
import Whatsapp from './Whatsapp';

const Footer =()=>{
    const footerStyle = {
        paddingTop: '40px',
        backgroundImage: `url(${backGround_Image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    };

    const [Page, setState] = useState([])

    useEffect(() => {
       var config = {
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
           },
           withCredentials: false
         }
     
       axios(URL+'api/mk/contact_get',config)
           .then(function (response) {
            setState(response.data);
           
           });
     }, [setState]);

    return(
        <>
            <div className="text-center">
            <img src={mainimage} className="img-fluid"></img>
            </div>
            <Whatsapp></Whatsapp>
            <section className="footer-area" style={footerStyle}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-6">
                            <div className="mt-25px mb-25px newTap">
                                { Page.logo ?
                                    <img src={URL + 'public/upload/setting/'+ Page.logo } className="mb-20px" alt="MKSOL TECH" style={{ height: '120px'}} /> :null
                                }
                                
                                <p className="mb-20px " style={{ fontSize: '12px',color: 'white'}}>{ Page.about }</p>
                                {/* <NavLink to="#0" className="social color-blue color-fff-hvr bg-orange-hvr bg-gray text-center radius-50 fs-15 d-inline-block"><i className="fa fa-facebook"></i> </NavLink>
                                <NavLink to="#0" className="social color-blue color-fff-hvr bg-orange-hvr bg-gray text-center radius-50 fs-15 d-inline-block"><i className="fa fa-twitter"></i> </NavLink>
                                <NavLink to="#0" className="social color-blue color-fff-hvr bg-orange-hvr bg-gray text-center radius-50 fs-15 d-inline-block"><i className="fa fa-linkedin"></i> </NavLink>
                                <NavLink to="#0" className="social color-blue color-fff-hvr bg-orange-hvr bg-gray text-center radius-50 fs-15 d-inline-block"><i className="fa fa-behance"></i> </NavLink> */}
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="mt-25px mb-25px links">
                                <h4 className="mb-20px">Company</h4>
                                <h6 className="mb-10px">
                                    <i className="fa fa-angle-right"></i> 
                                    <NavLink to="/" className="color-333 color-blue-hvr transition-3">Home</NavLink>
                                </h6>
                                <h6 className="mb-10px">
                                    <i className="fa fa-angle-right"></i> 
                                    <NavLink to="about" className="color-333 color-blue-hvr transition-3">About</NavLink>
                                </h6>
                                <h6 className="mb-10px">
                                    <i className="fa fa-angle-right"></i> 
                                    <NavLink to="service" className="color-333 color-blue-hvr transition-3">Services</NavLink>
                                </h6>    
                                <h6 className="mb-10px">
                                    <i className="fa fa-angle-right"></i> 
                                    <NavLink to="/portfolio" className="color-333 color-blue-hvr transition-3">Portfolio</NavLink>
                                </h6>
                            </div>
                        </div>
                        
                        <div className="col-lg-3 col-sm-6">
                            <div className="mt-25px mb-25px links">
                                <h4 className="mb-20px">Useful Links</h4>
                                <h6 className="mb-10px">
                                    <i className="fa fa-angle-right"></i> 
                                    <NavLink to="terms" className="color-333 color-blue-hvr transition-3">Terms and conditions</NavLink>
                                </h6>
                                <h6 className="mb-10px">
                                <i className="fa fa-angle-right"></i> 
                                    <NavLink to="/Privacy" className="color-333 color-blue-hvr transition-3">Privacy</NavLink>
                                </h6>
                                <h6 className="mb-10px">
                                <i className="fa fa-angle-right"></i> 
                                    <NavLink to="/faq" className="color-333 color-blue-hvr transition-3">FAQ's</NavLink>
                                </h6>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="mt-25px mb-25px">

                                    <h4 className="mb-20px">Contact Info</h4>
                                    <p className="mb-20px" style={{ fontSize: '12px',color: 'white'}}>Drop us a line or give us a ring. We love to hear from you and are happy to answer any questions.</p>
                                    <h6><i className="fa fa-map-marker mr-5px fs-15 color-blue bg-gray radius-50 address text-center"></i>{ Page.address }</h6>
                                    <h6><i className="fa fa-phone mr-5px fs-15 color-blue bg-gray radius-50 address text-center"></i>{ Page.phone }</h6>
                                    <h6 className="mb-30px"><i className="fa fa-envelope mr-5px fs-15 color-blue bg-gray radius-50 address text-center"></i>{ Page.email }</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="text-center" style={{ backgroundColor: '#060c46',color: 'white',padding: '10px' }}>
                <div className="container">
                    <h6 className="mb-0px" style={{ fontSize: '12px'}}>Copyright © 2021  by MKSOL TECH. All Rights Reserved</h6>
                </div>
            </section>
        </>
    )
}

export default Footer;