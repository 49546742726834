import React,{useState,useEffect } from "react";
import Nav from '../layout/Topnav';
import Footer from '../layout/Footer';
import Header from '../layout/Pageheader';
import axios from "axios";
import {URL} from '../Request';
import Fade from 'react-reveal/Fade';

const Faq = () =>{
        
         const [Page, setState] = useState([])

         useEffect(() => {
            var config = {
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                },
                withCredentials: false
              }
          
            axios(URL+'api/mk/faq',config)
                .then(function (response) {
                    setState(response.data);
                
                });
          }, [setState]); 
    return(
        <>
            <Nav></Nav>
            <Header title="FAQ's "/>
            <>
                <div className="pages-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mt-5 mb-5">
                                <div className="">
                                    <div className="services-text pl-30px pr-30px mt-25px mb-25px wow fadeInUp" >
                                        <div className="pl- text-left">
                                            {Page.map((user) => (
                                                <>
                                                    <Fade bottom>
                                                        <h3 style={{fontSize : '23px',fontWeight: '600',textAlign: 'left',marginBottom: '5px'}}>{user.question}</h3>
                                                        <p className="mt-15px text-left">{user.answer}</p>
                                                    </Fade>
                                                </>   
                                            ))}                       
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <Footer/>
        </>
    )
}

export default Faq;