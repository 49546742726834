import React from 'react';
import Bounce from 'react-reveal/Bounce';
import { NavLink } from 'react-router-dom';
import about_home from '../images/section/about_home.png';


const About = () =>{

    return(
        <>
            <section className="about-area sec-padding about_section pt-5 pb-5" >
                <div className="container mt-3 mb-3">
                    <div className="row mb-25px">
                        <div className="col-md-6">
                            <div className="mt-25px mb-25px ">
                            <Bounce left cascade>
                                <span className="fs-20 fw-400 pt-10px pb-10px pr-25px pl-25px radius-50px bg-gray color-blue mktag">MKSOL TECH</span>
                                <h3 className="mb-15px mt-20px h-heading">MKSOL TECH </h3>
                                </Bounce>
                                <Bounce cascade>
                                <p class="p-texting">MKSOL TECH is an IT company, providing Services in Web Designing, Web Development, Online Applications for Business Process Automation and trainings.</p>
                                <p className="p-texting">MKSOL TECH has a vast clients based in national and international spectrum. Our portfolio tells the quality of service we have been providing to our valuable clients. We have expertise in Web Design, Customize Web Development, e-commerce Solutions, Business Management Applications Development e.g: Production Management, Human Resource Management, Inventory Management, Complaints Management, Search Engine Optimization (SEO) and Internet Marketing.</p>
                                <NavLink className="main-btn btn-3 mt-30px" to="/about">Read More</NavLink>
                                </Bounce>
                            </div>
                        </div>
                        <div className="col-md-6 text-center">
                            <div className="mt-25px mb-25px ">
                                <Bounce right cascade>
                                    <img src={ about_home } alt="mksoltech " className="about_img" />
                                </Bounce>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About;

