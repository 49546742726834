import React,{useEffect,useState} from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../images/logo.png';
import './Laystyle.css';
import Zoom from 'react-reveal/Zoom';
import axios from "axios";
import {URL} from '../Request';

const Topnav = () => {
    const [Page, setState] = useState([])

    useEffect(() => {
       var config = {
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
           },
           withCredentials: false
         }
     
       axios(URL+'api/mk/contact_get',config)
           .then(function (response) {
            setState(response.data);
           
           });
     }, [setState]);

    return(
        <>
        {/* <Zoom top> */}
        <div className="container-fluid bg-white sticky-top">
        <div className="container ">
            
            <nav className="navbar navbar-expand-md ">
            <NavLink className="navbar-brand" to="/">
            { Page.logo ?
                  <img src={URL + 'public/upload/setting/'+ Page.logo } className="nav_logo" alt="MKSOL TECH"  /> :null
            }
            </NavLink>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">

                <ul className="navbar-nav ml-auto new-list">
                    <li className="nav-item">
                        <NavLink activeClassName="active" className="nav-link" to="/">Home</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink activeClassName="active" className="nav-link" to="/about">About</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink activeClassName="active" className="nav-link" to="/service">Services</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink activeClassName="active" className="nav-link" to="/portfolio">Portfolio</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink activeClassName="active" className="nav-link" to="/contact">Contact Us</NavLink>
                    </li>
                </ul>
            </div>
            </nav>
            </div>
        </div>
    {/* </Zoom> */}
        </>
    )
}

export default Topnav;