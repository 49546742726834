import React,{useState,useEffect } from "react";
import Nav from '../layout/Topnav';
import Footer from '../layout/Footer';
import Header from '../layout/Pageheader';
import axios from "axios";
import {URL} from '../Request';
import Fade from 'react-reveal/Fade';

const Portfolio = () =>{

    const [Page, setState] = useState([])

    const style_bage = {
        fontSize: '14px',
        fontWeight: '100',
        padding: '7px 13px',
        borderRadius: '60px',
        position: 'absolute',
        marginTop: '-16px',
        marginLeft: '20px'
    }
    useEffect(() => {
       var config = {
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
           },
           withCredentials: false
         }
     
            axios(URL+'api/mk/portfolio',config)
                .then(function (response) {
                    setState(response.data);
           
           });
     }, [setState]);  
           
    return(
        <>
            
            <Nav></Nav>
           
            
            <Header title="Portfolio "/>
            <section className="about-area sec-padding about_section pt-5 pb-5" >
                <div className="container mt-3 mb-3">
                    <div className="row mb-25px">
                        <Fade bottom>
                            <div className="col-md-12 text-center mb-5">
                                <p>MKSOL TECH expertise in the creation of website designing, graphic designing and branding helps to boost your sales. Our company creates brand recognition and exceptional client experience.</p>
                            </div>
                        </Fade>

                        <div className="col-md-12">
                            <div className="row">

                            {Page.map((portfolio) => (
                                <div className="col-md-4 mb-2">
                                    
                                        <a href={portfolio.link} target="_blank" rel="noopener noreferrer"  style={{ textDecoration:'none'}}>
                                            <Fade bottom>
                                                <div className="card">
                                                    <div className="p-2">
                                                        <img src={URL+'public/upload/portfolio/'+portfolio.image} className="img-fluid" alt={ portfolio.name }/>
                                                    </div>
                                                    <div>
                                                        <div className="badge badge-primary" style={ style_bage }>{portfolio.category}</div>
                                                        <h3 style={{ fontSize: '17px',padding: '9px 24px',marginTop: '14px',color:'black'}}>{portfolio.name}</h3>
                                                    </div>
                                                </div>
                                            </Fade>
                                        </a>
                                    
                                </div>
                            ))}

                            </div>
                        
                        </div>
                    </div>
                </div>
            </section>

            {/* Add Footer Page */}
            <Footer/>
            {/* Add Footer Page */}

        </>
    )

}

export default Portfolio;