import React from 'react';
import service_image from '../images/section/service_1.png';
import backGround_Image from '../images/back_ground/cover-1.jpg';
import Fade from 'react-reveal/Fade';

const Ourservices =()=>{

    const sectionStyle= {
        backgroundImage: `url(${backGround_Image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    }

    return(
        <>
            <section className="about-area sec-padding " style={sectionStyle}>
                <div className="container">
                    <div className="row mb-25px">
                        <div className="col-md-6">
                            <Fade bottom>
                                <div className="mt-25px mb-25px wow fadeInLeft" >
                                    <span className="fs-20 fw-400 pt-10px pb-10px pr-25px pl-25px radius-50px bg-gray color-blue mktag">MKSOL TECH</span>
                                    <h3 className="mb-15px mt-20px h-heading">Our Services</h3>
                                    <p className="mb-15px ">
                                        </p><p className="p-texting">We pride ourselves on providing a comprehensive suite of solutions comprising of infrastructure consultancy, on/offsite services, custom software and web development, software and Mobile Application, web application Developing testing, and enterprise architecture consulting.</p>
                                        <p className="p-texting">Our team consistently delivers state-of-the-art solutions in various areas including, but not limited to, integrated business solutions, system applications, product development, Internet/Intranet applications and communication &amp; network management services.</p>                    <p></p>

                                </div>
                            </Fade>
                        </div>
                        <div className="col-md-6 text-center">
                            <div className="mt-25px mb-25px wow fadeInRight" >
                                <Fade bottom>
                                    <img src={ service_image } alt="img" className="about_img" />
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}

export default Ourservices;