import React from 'react';
import Zoom from 'react-reveal/Zoom';

const Pageheader = (props) =>{
    return(
        <>

        <section className="services-area-5 sec-padding text-center p-relative pageheader-section">
            <div className="container">
            <Zoom bottom>
                <h2 style={{ color: 'black',fontWeight: '600',fontSize: '34px'}}>{props.title}</h2>
                <p style={{fontSize: '14px',color: '#080e4c'}}> Home / {props.title}</p>
            </Zoom>
            </div>
        </section>

        </>
    )
}

export default Pageheader;