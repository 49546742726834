import React,{useState,useEffect} from 'react';
import Nav from '../layout/Topnav';
import Footer from '../layout/Footer';
import Header from '../layout/Pageheader';
import Ourservices from './Ourservices';
import '../Home/Home.css';
import Ourpackage from './Ourpackage';
import Package from './Package';
import axios from "axios";
import {URL} from '../Request';



const Service = () => {

    const sectionStyle= {
        backgroundImage: 'url("https://www.mksoltech.com/public/images/cover-2.jpg")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    }

    const [Page, setState] = useState([])

    useEffect(() => {
       var config = {
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
           },
           withCredentials: false
         }
     
       axios(URL+'api/mk/service_service',config)
           .then(function (response) {
               setState(response.data);
           
           });
     }, [setState]);

        const services_list = Page.map((client) => {
            if(client.service == 1){
               return <Package key={client.id} title={client.title} icon={client.icon} subtitle={client.subtitle} description={client.description}/>
            }
            
        })

        const packages_list = Page.map((client) => {
            if(client.develop == 1){
               return <Package key={client.id} title={client.title} icon={client.icon} subtitle={client.subtitle} description={client.description}/>
            }
            
        })
        return(
            <>
            <Nav/>
            <Header title="Service"/>
            <Ourservices/>
            <section className="about-area sec-padding" style={sectionStyle}>
                <div className="container ">
                    <div className="row">
                        { services_list }
                    </div>
                </div>
            </section>
            <Ourpackage/>
            <section className="about-area sec-padding" style={sectionStyle}>
                <div className="container ">
                    <div className="row">
                       { packages_list }
                    </div>
                </div>
            </section>
            <Footer/>
            </>
        )
}

export default Service;