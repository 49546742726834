import React from 'react';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import increase_image from '../images/section/service_v3-1.png';

const Increase =()=>{
    return(
        <>
            <section className="bg-gray increase-section" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="mt-25px mb-25px">
                            <Fade left big>
                                <img alt="mksoltech" className="div-img" src={ increase_image } style={{ width: '100%'}} />
                            </Fade>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="mt-25px mb-25px">
                                <span className="fs-14 fw-400 color-blue">Highly Creative Soultions</span>
                                <h3 className="mb-15px mt-10px">Increase You Business With MKSOL TECH</h3>
                                <p className="mb-20px">Our company helps other companies grow. We can suggest growth strategies based on our analysis of you and your competitors.</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Zoom bottom>
                                            <div className="mt-10px mb-10px p-relative">
                                                <i className="fa fa-star color-blue fs-35 p-absolute star-bar"></i>
                                                <div className="pl-60px">
                                                    <h5>Web Development</h5>
                                                </div>
                                            </div>
                                        </Zoom>
                                    </div>
                                    <div className="col-md-6">
                                        <Zoom bottom>
                                            <div className="mt-10px mb-10px p-relative">
                                                <i className="fa fa-star color-blue fs-35 p-absolute star-bar"></i>
                                                <div className="pl-60px">
                                                    <h5>Creative
                                                        Web Design
                                                    </h5>
                                                </div>
                                            </div>
                                        </Zoom>
                                    </div>
                                    <div className="col-md-6">
                                        <Zoom bottom>
                                            <div className="mt-10px mb-10px p-relative">
                                                <i className="fa fa-star color-blue fs-35 p-absolute star-bar"></i>
                                                <div className="pl-60px">
                                                    <h5>Web
                                                        Hosting
                                                    </h5>
                                                </div>
                                            </div>
                                        </Zoom>
                                    </div>
                                    <div className="col-md-6">
                                        <Zoom bottom>
                                            <div className="mt-10px mb-10px p-relative">
                                                <i className="fa fa-star color-blue fs-35 p-absolute star-bar"></i>
                                                <div className="pl-60px">
                                                    <h5>IT
                                                        Outsorcing
                                                    </h5>
                                                </div>
                                            </div>
                                        </Zoom>
                                    </div>
                                    <div className="col-md-6">
                                        <Zoom bottom>
                                            <div className="mt-10px mb-10px p-relative">
                                                <i className="fa fa-star color-blue fs-35 p-absolute star-bar"></i>
                                                <div className="pl-60px">
                                                    <h5>E-commerce
                                                        Websites
                                                    </h5>
                                                </div>
                                            </div>
                                        </Zoom>
                                    </div>
                                    <div className="col-md-6">
                                        <Zoom bottom>
                                            <div className="mt-10px mb-10px p-relative">
                                                <i className="fa fa-star color-blue fs-35 p-absolute star-bar"></i>
                                                <div className="pl-60px">
                                                    <h5>SEO</h5>
                                                </div>
                                            </div>
                                        </Zoom>
                                    </div>

                                    <div className="col-md-6">
                                        <Zoom bottom>
                                            <div className="mt-10px mb-10px p-relative">
                                                <i className="fa fa-star color-blue fs-35 p-absolute star-bar"></i>
                                                <div className="pl-60px">
                                                    <h5>Custom
                                                        Software
                                                    </h5>
                                                </div>
                                            </div>
                                        </Zoom>
                                    </div>

                                    <div className="col-md-6">
                                        <Zoom bottom>
                                            <div className="mt-10px mb-10px p-relative">
                                                <i className="fa fa-star color-blue fs-35 p-absolute star-bar"></i>
                                                <div className="pl-60px">
                                                    <h5>Mobile Apps
                                                        Development
                                                    </h5>
                                                </div>
                                            </div>
                                        </Zoom>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Increase;