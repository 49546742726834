import React from 'react';
import Service from './Service';


const Mainservice = ()=>{
    return(
        <>
            <section>
                <div className="container pt-5 pb-4">
                    <div className="row">
                        <Service code="fa fa-code icon-font-code" title="Web Development" description="We at MKSOL TECH , provides you the best web development services for your business requirements. Call Us Today." />
                        <Service code="fa fa-android icon-font-android" title="Android Development" description="At MKSOL TECH, we are providing you the best app development solutions, especially android and ios solutions, contact us today." />
                        <Service code="fa fa-search icon-font-search" title="Digital Marketing" description="MKSOL TECH provides you the best digital marketing services for your company. Get the best marketing services today." />
                        <Service code="fa fa-cart-plus icon-font-code" title="E Commerce Solutions" description="We are delivered E-Commerce solutions to various companies across the globe." />
                        <Service code="fa fa-clock-o icon-font-android" title="Time Commitment" description="We deliver quality services in short period our efficient team make it possible." />
                        <Service code="fa fa-money icon-font-search" title="Budget Friendly" description="We offering complete I.T solution in corporate sector with budget friendly packages." />
                    </div>
                </div>
            </section>

        </>
    )
}

export default Mainservice;