import React from 'react';
import image_1 from '../images/section/package_1.png';
import backGround_Image from '../images/back_ground/cover-1.jpg';
import Fade from 'react-reveal/Fade';

const Ourpackage =()=>{

    const sectionStyle= {
        backgroundImage: `url(${backGround_Image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    }

    return(
        <>
            <section className="about-area sec-padding " style={sectionStyle}>
                <div className="container">
                    <div className="row mb-25px">
                        
                        <div className="col-md-6 text-center">
                            <div className="mt-25px mb-25px wow fadeInRight" >
                                <Fade bottom>
                                    <img src={ image_1 } alt="mksoltech packages list" className="about_img" />
                                </Fade>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <Fade bottom>
                                <div className="mt-25px mb-25px wow fadeInLeft" >
                                    <span className="fs-20 fw-400 pt-10px pb-10px pr-25px pl-25px radius-50px bg-gray color-blue mktag">MKSOL TECH</span>
                                    <h3 className="mb-15px mt-20px h-heading">Our Developed Packages</h3>
                                    <p className="mb-15px ">
                                        </p><p className="p-texting">
                                        In software development, as in other scalable projects, small projects are easier — they are easier to plan for, define, estimate and work on, etc. It’s also much easier to track goals in small projects and to see how well those goals are met.
                                        </p>
                                        <p className="p-texting">
                                        Our software development process essentially takes a project, no matter how large, and breaks it down into smaller projects that are well-defined and efficiently managed. This process gives our clients the chance to evaluate the progress, value, and usefulness of their application in incremental stages, thus helping the project stay on budget, on schedule, and on point.
                                        </p>

                                </div>
                            </Fade>
                        </div>
                        
                    </div>
                </div>
            </section>
        </>
    )

}

export default Ourpackage;