import React from 'react';
import slider_img from '../images/slider_1.png';
import Zoom from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Bounce';

const Slider = () =>{

    return(
        <>
            <div className="container cont-ver" >
                <div className="row">
                    <div className="col-md-6">
                    <Zoom left>
                        <div className="slider_section">
                        <Bounce left cascade>
                            <h1>Web Development - Complete Solutions</h1>
                        </Bounce>
                            <div>
                                <p>MKSOL TECH (Pvt) is an innovative web designing and development company based in Islamabad,
                                 Pakistan. We are a team of committed web developers committed to serve our clients with latest online
                                  technologies.Our goal is cater to the needs of small to mide sized comnpanies and start-ups planning
                                   to establish their online presence. </p>

                                <p>We specialise designing and developing web solutions that cater to the unique needs of your business.
                                 While delivering a project our goal is not only the feel and look but also how well it can acheive its
                                  purpose - the core of purpose of any live website.</p>
                            </div>
                        </div>
                        </Zoom>
                    </div>
                    <div className="col-md-6 text-center">
                    <Zoom right>
                        {/* <img src={slider_img} className="slider_imh" alt="mksoltech"  /> */}
                    </Zoom>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Slider;