import React,{useState,useEffect} from 'react';
import axios from "axios";
import {URL} from '../Request';
import './About.css';
import Slider from "react-slick";
import './css/slick.min.css';
import background_img from '../images/back_ground/back_1.png';
import Fade from 'react-reveal/Fade';


const Client = () => {
    const [Page, setState] = useState([])

    useEffect(() => {
       var config = {
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
           },
           withCredentials: false
         }
     
       axios(URL+'api/mk/clients',config)
           .then(function (response) {
               setState(response.data);
           
           });
     }, [setState]); 

     var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
        return(
            <>
            <section className="about-area sec-padding pt-5 pb-5" style={{backgroundImage:"url("+background_img+")"}}>
                <div className="container mt-3 mb-3">
                    <div className="row mb-25px">
                        <div className="col-md-12">
                          <Fade bottom>
                              <div className="mt-25px mb-25px text-center">
                                  <span className="fs-20 fw-400 pt-10px pb-10px pr-25px pl-25px radius-50px bg-gray color-blue mktag">MKSOL TECH</span>
                                  <h3 className="mb-15px mt-20px h-heading">Our Happy Clients </h3>
                                  <p className="p-texting text-center mb-5">MKSOL TECH is an IT company,  Web Development, Process Automation and trainings.</p>
                                  <Slider {...settings}>
                                  {Page.map((client) => (
                                          <div style={{padding: '10px'}}>
                                              <div className="load_logo">
                                                  <a href={client.link} target="_blank">
                                                      <img src={URL+'public/upload/client/'+client.logo} alt={ client.name } ></img>
                                                  </a>
                                              </div>
                                          </div>   
                                      ))} 
                                  </Slider>
                              </div>
                          </Fade>
                        </div>
                    </div>
                </div>
            </section>
            </>
        )
}

export default Client;