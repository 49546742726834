import React from 'react';
import './About.css';
import about_1 from '../images/section/about_1.png';
import Fade from 'react-reveal/Fade';

const AboutUs = () => {

        return(
            <>
            <section className="about-area sec-padding about_section pt-5 pb-5" >
                <div className="container mt-3 mb-3">
                    <div className="row mb-25px">
                        <div className="col-md-6">
                        <Fade bottom>
                            <div className="mt-25px mb-25px ">
                                <span className="fs-20 fw-400 pt-10px pb-10px pr-25px pl-25px radius-50px bg-gray color-blue mktag">MKSOL TECH</span>
                                <h3 className="mb-15px mt-20px h-heading">About MKSOL TECH </h3>
                                <p class="p-texting">
                                MKSOL TECH (Ptv). Ltd is a professional web design company in Islamabad and Kahuta. We provide complete web design services to make your product to be successful in market with eye-catching outlook and appropriate web design. We also provide advance services in the field of web development, desktop applications, Internet marketing (SEO), mobile application development etc. 
                                </p>

                                <p class="p-texting">
                                We specialize in projects that have beautiful design combined with interactive technology. We work closely with our clients from concept to delivery, helping turn their visions into their Websites. Every project we commence is given our supreme attention. We operate from Islamabad, Pakistan and strongly believe that the resources we have are best fit for small, medium or large scale projects. We seek ideas from our daily life and utilize the power of creative freedom to generate ROI for our clients.
                                </p>
                                <p class="p-texting">
                                We also help companies attain cost effective outsourcing and offshore staffing solutions. If you want the best website possible that generates real results, allow us!
                                </p>
                            </div>
                        </Fade>
                        </div>
                        <div className="col-md-6 text-center">
                            <div className="mt-25px mb-25px ">
                                <Fade bottom>
                                    <img src={ about_1 } alt="img" className="about_img" />
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
        )
}

export default AboutUs;