import React from 'react';
import Fade from 'react-reveal/Fade';

const Service = (props) =>{
    return(
        <>
            <div className="col-md-4 mb-4 ">
                <Fade bottom>
                            <div className="card pb-5 card-m text-center">
                                <div>
                                    <div>
                                        <div className="icon_box">
                                            <i className={props.code}></i>
                                        </div>
                                        <h2 className="h-text">{props.title}</h2>
                                        <p className="p-text">{props.description}</p>
                                    </div>
                                </div>
                            </div>
                </Fade>
             </div>
        </>
    )
}

export default Service;