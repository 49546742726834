import React from 'react';
import Nav from '../layout/Topnav';
import Footer from '../layout/Footer';
import Header from '../layout/Pageheader';
import E404_Image from '../images/404.png';
import { NavLink } from 'react-router-dom';

const Error_404 = () =>{

    return(
        <>
          <Nav/>
          <Header title="Error Page"/>
          <div>
              <div  className="container text-center">
              
              <img src={E404_Image} className="mb-5 mt-4"></img>
              <p><b>Either Something went wrong or the page doesn't exist anymore</b></p>
              <NavLink className="btn btn-primary mb-4" to="/">Back to Home</NavLink>
              </div>
          </div>
          
            
          <Footer/> 
        </>
    )
};

export default Error_404;