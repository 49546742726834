import React,{useState,useEffect } from "react";
import Nav from '../layout/Topnav';
import Footer from '../layout/Footer';
import Header from '../layout/Pageheader';
import axios from "axios";
import {URL} from '../Request';
import ReactHtmlParser from 'react-html-parser';
import Fade from 'react-reveal/Fade';

const Terms = () =>{

    const [Page, setState] = useState([])

    useEffect(() => {
       var config = {
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
           },
           withCredentials: false
         }
     
       axios(URL+'api/mk/Terms',config)
           .then(function (response) {
               setState(response.data);
           
           });
     }, [setState]);  
           
    return(
        <> 
            <Nav></Nav>
            <Header title="Terms and conditions "/>
                <div className="pages-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mt-5 mb-5">
                                <div className="">
                                    <div className="services-text pl-30px pr-30px mt-25px mb-25px wow fadeInUp" >
                                        <Fade bottom>
                                            <div className="pl- text-left">
                                                    { ReactHtmlParser(Page.text)}                      
                                            </div>
                                        </Fade>    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            <Footer/>
        </>
    )

}

export default Terms;