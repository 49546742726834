import React from 'react';
import Fade from 'react-reveal/Fade';
import Image from '../images/section/about-2.png';
const Steps = () =>{
    return(
        <>
            <section className="bg-gray setp-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mt-25px mb-25px">
                                <span className="fs-14 fw-400 color-blue">OUR SMART PROCESS</span>
                                <h3 className="mb-15px mt-10px">Steps in Our Works</h3>
                                <p className="mb-20px">Our company helps other companies grow. We can suggest growth strategies based on our analysis of you and your competitors.</p>
                                <div className="row">
                                <Fade bottom big>
                                    <div className="col-md-12">
                                        <div className="mt-10px mb-10px p-relative">
                                            <i className="fa fa-star color-blue fs-35 p-absolute star-bar"></i>
                                            <div className="pl-60px">
                                                <h5>Collect Ideas</h5>
                                                <p style={{ fontSize: '13px',lineHeight: '20px',fontWeight: '400',color: '#0a0a0a'}}>
                                                After meeting with client as a result our expert collect detailed description where different types of solutions discussed and also shared, hence they finalize most innovative approach. </p>
                                            </div>
                                        </div>
                                    </div>
                                    </Fade>

                                    <Fade bottom big>
                                        <div className="col-md-12">
                                            <div className="mt-10px mb-10px p-relative">
                                                <i className="fa fa-star color-blue fs-35 p-absolute star-bar"></i>
                                                <div className="pl-60px">
                                                    <h5>Data Analysis</h5>
                                                    <p style={{ fontSize: '13px',lineHeight: '20px',fontWeight: '400',color: '#0a0a0a'}}>First of all we analyse the data from different aspects, also using different professional tools as a result we meet the exact solution which our client want.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Fade>
                                    <Fade bottom big>
                                    <div className="col-md-12">
                                        <div className="mt-10px mb-10px p-relative">
                                            <i className="fa fa-star color-blue fs-35 p-absolute star-bar"></i>
                                            <div className="pl-60px">
                                                <h5>Magic Touch</h5>
                                                <p style={{ fontSize: '13px',lineHeight: '20px',fontWeight: '400',color: '#0a0a0a'}}>Our programmers start the development, also our designers start making beautiful even more attractive design to make the product more perfect.</p>
                                            </div>
                                        </div>
                                    </div>
                                    </Fade>

                                    <Fade bottom big>
                                    <div className="col-md-12">
                                        <div className="mt-10px mb-10px p-relative">
                                            <i className="fa fa-star color-blue fs-35 p-absolute star-bar"></i>
                                            <div className="pl-60px">
                                                <h5>Finalize Product</h5>
                                                <p style={{ fontSize: '13px',lineHeight: '20px',fontWeight: '400',color: '#0a0a0a'}}>After development our QA and Testing team furthermore start testing and also test the final deliverable in different scenarios hence they get the product ready for deployment.</p>
                                            </div>
                                        </div>
                                    </div>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                        <Fade right big>
                            <div className="mt-25px mb-25px">
                                <img alt="img" src={ Image } className="div-img" style={{ width:'100%'}} />
                            </div>
                        </Fade>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Steps;