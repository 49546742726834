import React,{useState,useEffect} from 'react';
import Nav from '../layout/Topnav';
import Slider from './Slider';
import Mainservice from './Mainservice';
import About from './About';
import './Home.css';
import Email from './Email';
import Increase from './Increase';
import Steps from './Steps';
import Footer from '../layout/Footer';
import DocumentMeta from 'react-document-meta';
import axios from "axios";
import {URL} from '../Request';
//import backGround_Image from '../images/back_ground/h6-bg-section-02.png';
//import backGround_Image from '../images/back_ground/08.jpg';
import backGround_Image from '../images/back_ground/image-5.jpg';



const Home = () => {

    const [Seo, setState] = useState([])
    const pagename = "home";

    const style_bage = {
        fontSize:     '14px',
        fontWeight:   '100',
        padding:      '7px 13px',
        borderRadius: '60px',
        position:     'absolute',
        marginTop:    '-16px',
        marginLeft:   '20px'
    }

    useEffect(() => {
       var config = {
           headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
           },
           withCredentials: false
         }
       axios(URL+'api/mk/get_page/'+pagename,config)
           .then(function (response) {
               setState(response.data);
           
           });
     }, [setState]);

    const meta = {
        title: Seo.meta_title,
        description: Seo.meta_description,
        meta: {
          name: {
            keywords: Seo.key_words,
          }
        }
      };

    return(
        <>
        <DocumentMeta {...meta}>
        <section style={{ backgroundImage:`url(${backGround_Image})`,height: '100vh',backgroundSize:'cover',backgroundRepeat: 'no-repeat'}}>
        <Nav></Nav>
        <Slider/>
        </section>
        <Mainservice/>
        <About/>
        <Email/>
        <Increase/>
        <Steps/>
        <Footer/>
        </DocumentMeta>
        </>
    )
}

export default Home;